import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import {
	LegalNoticeProps,
	LegalNoticeState,
	LegalNoticeFlagsInterface
} from '@/Modules/LegalNotice/Common/LegalNotice.interface';
import { isPast, parseISO } from 'date-fns';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import {
	LegalNoticeControlsState
} from '@/Modules/LegalNotice/Components/LegalNoticeControls/LegalNoticeControls.interface';

const initState: LegalNoticeState = {
	isLoading: true,
	isDeleteLoading: false,
	isQuoteLoading: false,
	errorMessage: null,
	legalNotice: {} as LegalNoticeInterface,
	documentKey: 0,
};

export class LegalNoticeAdminService extends BaseComponentService<LegalNoticeProps, LegalNoticeState>
{
	apiLegalNoticeAdminService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeProps, initState);

		// Bind
		this.getLegalNoticeFlags = this.getLegalNoticeFlags.bind(this);
		this.handleAction = this.handleAction.bind(this);
	}

	/**
	 * Initialize the service by setting the context show and name
	 * @return Promise<void>
	 */
	async init(legalNoticeId: number): Promise<void>
	{
		try {
			await this.show(legalNoticeId);
		} catch (error: any) {
			this.setState({ isLoading: false });
			console.error('Failed to init data LEGALNOTICEADMINSERVICE', error.errorMessage);
		}
	}

	/**
	 * Legal notice
	 * @return Promise<LegalNoticeInterface | undefined>
	 */
	async show(legalNoticeId: number): Promise<LegalNoticeInterface | undefined>
	{
		try {
			const legalNotice = await this.apiLegalNoticeAdminService.show(legalNoticeId);
			this.setState({ legalNotice }, () => this.setState({ isLoading: false }));
			return legalNotice;
		} catch (error: any) {
			console.log('Failed to fetch legal notice show', error.message);
			return undefined;
		}
	}

	getLegalNoticeFlags(): LegalNoticeFlagsInterface
	{
		const { legalNotice } = this.state;

		// Verify if publishDate exists before using parseISO
		const isPublicationDatePast = legalNotice.publishDate ? isPast(parseISO(legalNotice.publishDate)) : false;
		const isSupplier = !!legalNotice.extSupplierUid;
		const isDraft = legalNotice.status?.toString() === PublishStateEnum.DRAFT.value;
		const isCancelled = legalNotice.status?.toString() === PublishStateEnum.CANCEL.value;
		const isQuote = legalNotice.status?.toString() === PublishStateEnum.QUOTE.value;
		const isQuotePending = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.PENDING.value;
		const isQuoteAnswerRefused = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.REFUSED.value;
		const isQuoteAnswerAccepted = legalNotice.quoteStatus?.toString() === QuoteStatusEnum.ACCEPTED.value;
		const isPaid = legalNotice.paymentStatus?.toString() === PaymentStateEnum.PAID.value;
		const isPublished = legalNotice.status?.toString() === PublishStateEnum.PUBLISH.value;
		const isError = legalNotice.status?.toString() === PublishStateEnum.ERROR.value;

		const canEdit = !isPublished && !isError && (isDraft || isQuote || (!isCancelled && !isPublicationDatePast));

		return {
			isPublicationDatePast,
			isSupplier,
			isDraft,
			isCancelled,
			isQuote,
			isQuotePending,
			isQuoteAnswerRefused,
			isQuoteAnswerAccepted,
			isPaid,
			isPublished,
			isError,
			canEdit,
		} as const;
	}

	/**
	 * Handle CallBack State from LegalNoticeControls
	 * @param legalNoticeControlsState
	 * @param currentState
	 */
	async handleAction(
		legalNoticeControlsState: LegalNoticeControlsState,
		currentState: LegalNoticeState
	): Promise<LegalNoticeControlsState | undefined>
	{

		if(legalNoticeControlsState.isDeleted) {
			this.setState({ isDeleteLoading: true });
			await this.show(legalNoticeControlsState.legalNotice.id);
			this.setState({
				documentKey: Math.random(),
				isDeleteLoading: false,
			});
		}

		if (legalNoticeControlsState.isLoadingDeleted) {
			this.setState({ isDeleteLoading: legalNoticeControlsState.isLoadingDeleted });
			return;
		}

		if (legalNoticeControlsState.legalNotice) {
			this.setState({
				legalNotice: legalNoticeControlsState.legalNotice,
				documentKey: Math.random(),
			});
		}

		try {
			// Handle Delete | handle duplicate
			if (legalNoticeControlsState.isDeleted || legalNoticeControlsState.isDuplicate || legalNoticeControlsState.isQuoteResponse) {
				await this.show(currentState.legalNotice.id);
				this.setState(prevState => ({
					isQuoteLoading: false,
					documentKey: Math.random(),
				}));
			}

			this.setState(prevState => ({
				isQuoteLoading: false,
			}));

			return legalNoticeControlsState;

		} catch (error: any) {
			console.error(`Error occurred on ${ this.constructor.name }`, error);
			this.setState({ isQuoteLoading: false });
		}
	}

}